/* RoundOutTabs.css */

/* Reset margins and paddings for consistency */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* Clearfix for floated elements */
.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

/* Styles for the tab row */
.tabrow {
    text-align: center;
    list-style: none;
    padding: 0;
    line-height: 24px;
    height: 40px;
    overflow: hidden;
    font-size: 12px;
    font-family: verdana;
    position: relative;
}

.tabrow li {
    border: 1px solid #AAA;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%);
    display: inline-block;
    position: relative;
    z-index: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0 -2px;
    padding: 0 20px;
    line-height: 40px;

}

.tabrow li.selected {
    background: var(--background-paper);
    z-index: 2;
    border-bottom-color: var(--background-default);
    border-bottom: none;
    border-top: 1px solid #AAA;


}

.tabrow:before {
    position: absolute;
    content: " ";
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #AAA;
    z-index: 1;
}

.tabrow li:before,
.tabrow li:after {
    border: 1px solid #AAA;
    position: absolute;
    bottom: -1px;
    width: 5px;
    height: 5px;
    content: " ";
}

.tabrow li:before {
    left: -5px;
    border-bottom-right-radius: 6px;
    border-width: 0 1px 1px 0;
}

.tabrow li:after {
    right: -5px;
    border-bottom-left-radius: 6px;
    border-width: 0 0 1px 1px;
}

.tabrow li.selected:before {
    box-shadow: 2px 2px 0 var(--background-paper);
}

.tabrow li.selected:after {
    box-shadow: -2px 2px 0 var(--background-paper);
}

/* Adjustments for non-rounded tabs */
.tabrow li.flat {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -2px; /* Ensure no overlap on the right for flat tabs */
    margin-left: -2px; /* Ensure no overlap on the left for flat tabs */
    z-index: 1;
}

.tabrow li.selected.flat {
    border-bottom-color: transparent; /* or the color of your tab content area */
    border-bottom-left-radius: 0; /* Ensure bottom corners are not rounded */
    border-bottom-right-radius: 0;
}

.tabrow li.selected.flat:before,
.tabrow li.selected.flat:after {
    display: none; /* Remove pseudo-elements to clear shadows */
}

