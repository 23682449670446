/* tabs.css */

/* Reset margins and paddings for consistency */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* Clearfix for floated elements */
.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

/* Styles for the tab row to be full width and use flexbox */
.tabrow {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    height: 40px;
    overflow: hidden;
    font-size: 12px;
    position: relative;
}


.tabrow li {
    flex: 1 0 0; /* Grow to fill space, don't shrink, and start at 0 width */
    border: 1px solid #AAA;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%);
    position: relative;
    z-index: 1;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0 0px; /* Adjusted margin to ensure there's a little space between tabs */
    padding: 0 15px;
    line-height: 40px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    overflow: hidden; /* Ensures content that overflows will be hidden */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    text-overflow: ellipsis; /* Adds an ellipsis to signify clipped text */
}
.tabrow li.selected {
    background: var(--background-paper);
    z-index: 3;
    border-bottom-color: var(--background-default);
    border-bottom: 1px solid transparent;
    border-top: 1px solid #AAA;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;


}

.tabrow li.flat {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -2px; /* Ensure no overlap on the right for flat tabs */
    margin-left: -2px; /* Ensure no overlap on the left for flat tabs */
    z-index: 1;
}

.tabrow li.selected.flat {
    border-bottom-color: transparent; /* or the color of your tab content area */
    border-bottom-left-radius: 0; /* Ensure bottom corners are not rounded */
    border-bottom-right-radius: 0;
}

.tabrow li.manageTabs {
    max-width: 50px; /* Adjust as needed */
    padding: 0 10px; /* Less padding for '+' tab */
    text-align: center; /* Center the '+' symbol */
    position: relative; /* Make sure this is set to position the dot */
}

.tabrow li.manageTabs.colorCycle {
    animation: colorCycle 6s linear infinite; /* Apply color cycle to the background when class is added */
}

@keyframes colorCycle {
    0% { background-color: rgb(102, 213, 102); }
    16% { background-color: rgb(0, 225, 255); }
    32% { background-color: rgb(0, 149, 255); }
    48% { background-color: rgb(102, 213, 102); }
    68% { background-color: rgb(255, 255, 255); }
    74% { background-color: rgb(255, 255, 255); }
    89% { background-color: rgb(0, 149, 255); }
    100% { background-color: rgb(102, 213, 102); }
}

